window.openModalById = function(id) {
  var $modal = $(`#${id}`);

  if ($modal.length == 0) return;
  if ($modal.hasClass("is-active")) return;

  $modal.addClass("is-active");
  $("body").addClass("is-clipped");

  // Close with escape
  $(document).on('keydown', function(event) {
    if (event.key === "Escape") {
      closeModalById(id);
      $(this).off(event);
    }
  });
}

window.closeModalById = function(id) {
  var $modal = $(`#${id}`);

  if ($modal.length == 0) return;
  if (!$modal.hasClass("is-active")) return;

  $modal.removeClass("is-active");
  $("body").removeClass("is-clipped");
}


// Sets open and close event listeners on load
$(document).on("turbolinks:load", function() {
  // Open modals
  $("a[data-modal-id], button[data-modal-id]").click(function() {
    let id = $(this).data("modal-id");
    openModalById(id);
  });

  // Close modals clicking the X, the overlay or .custom-close
  $(".modal").find(".delete, .modal-close, .modal-background, .custom-close").click(function() {
    let $modal = $(this).closest(".modal");
    if ($modal.length == 0) return;

    closeModalById($modal.attr("id"));
  });
});


// Close modals before changing page
$(document).on("turbolinks:before-cache", function() {
  $(".modal.is-active").removeClass("is-active");
  $("body").removeClass("is-clipped");
});
