// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// jQuery //
global.jQuery = require("jquery");
global.$ = global.jQuery;
global.$.ajaxSetup({
  headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
});

// jQuery masks //
import 'jquery-mask-plugin';


// Currency.js //
global.currency = require("currency.js");
global.BRL = function(value) {
  let obj = currency(value, { symbol: "R$", separator: ".", decimal: "," });
  obj.formatDollars = () => currency(obj.dollars(), { separator: ".", precision: 0, formatWithSymbol: false}).format();
  obj.formatCents = () => obj.cents().toString().padStart(2, 0);
  return obj;
}


// Tiny-slider
import { tns } from 'tiny-slider/src/tiny-slider';
global.tns = tns;


// Javascript files
require("helpers/header");
require("helpers/modal");

// Create constant for default fetch headers
const csrfToken = $('meta[name="csrf-token"]').attr('content');
global.fetchHeaders = new Headers();
global.fetchHeaders.append('X-Requested-With', 'XMLHttpRequest');
global.fetchHeaders.append('X-CSRF-TOKEN', csrfToken);
global.fetchHeaders.append('Content-Type', 'application/json');


// Toast
window.renderToast = function(style, message, time = 2000) {
  let $message = $(`
    <div class="toast message is-${style}">
      <div class="message-body">
        ${message}
      </div>
    </div>
  `);

  $("#toastContainer").append($message);
  $message.delay(time).fadeOut();
}

$(document).on("turbolinks:visit", function() {
  $("#toastContainer").empty();
});


// No-click class
$(document).on("click", ".no-click", function(e) {
  return false;
});
